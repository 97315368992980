@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  color: #000000;
}

.content {
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1f1f1f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #595959;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* BLACK BACKGROUND BOX */
.backgroundBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 10px 20px 20px 20px;
  background-color: #f2f6fc;
}

/* INFO BOXES / CREATE BOXES*/
.infoBoxStyle {
  padding: 7px 15px;
  margin: 0px 0px 0px 0px;
  height: 60px;
  border-radius: 0.5em
}

/* LIST STYLE FOR CLUSTER ADND QUESTION SELECTION */
.listStyle {
  min-height: 0;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
  width: 50%;
  background-color: transparent;
  border-radius: 20px;
}

/* NAVIGATION BUTTON STYLE */
.navBtnStyle {
  width: 150px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  border: 2px solid #0070ba !important;
  color: rgb(0, 0, 0) !important;
}

.navBtnStyle:hover {
  background-color: #0070ba !important;
}

/* CHANGE FORM DEFAULT */
form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* STYLE FOR THE MODAL */
.modalStyle {
  display: flex;
  flex-direction: column;
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #d5eaf7;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.MuiDialog-root {
  outline: none;
}